import React from 'react';
import Link from 'next/link';
import { LightbulbIconOutline, SimpleCalendarIcon, AVFXContributor } from '../../modules/Icons';

export const containerStyle = 'sticky bottom-0 bg-a-dark-gray z-10 pb-6';
export const divider = 'border-t opacity-[20%] mx-4';
export const ulStyle = 'px-[41px] py-[16px]';
export const liStyle = 'leading-[48px] text-[15px] text-white-60';
export const linkStyle =
  'flex items-center text-white-60 stroke-[#7F898D] hover:text-a-blue hover:stroke-a-blue h-[40px]';

export const BottomLink: React.FC = () => (
  <div className={containerStyle}>
    <div className={divider}></div>
    <ul className={ulStyle}>
      <li className={liStyle}>
        <Link
          href="https://actionvfx.featurebase.app/?b=65bfb4720ed972a2431dad35"
          className={linkStyle}>
          <LightbulbIconOutline className="inline w-4 mr-4 stroke-inherit fill-inherit" />
          Request a Product
        </Link>
      </li>
      <li className={liStyle}>
        <Link href="https://actionvfx.featurebase.app/roadmap" className={linkStyle}>
          <SimpleCalendarIcon className="inline w-4 mr-4 stroke-inherit" />
          Product Roadmap
        </Link>
      </li>
      <li className={liStyle}>
        <Link href="https://sell.actionvfx.com/" className={linkStyle}>
          <AVFXContributor className="inline w-4 mr-4 stroke-inherit" />
          Sell on ActionVFX
        </Link>
      </li>
    </ul>
  </div>
);
