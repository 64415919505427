import React from 'react';
import Link from 'next/link';

export const selectedStyle = 'text-a-blue stroke-a-blue fill-a-blue bg-[#252B2D] rounded-[5px] h-[38px]';
export const defaultStyle = 'text-white stroke-white fill-white h-[38px]';

export const TopLevelLink: React.FC<{
  href: string;
  icon: React.ReactNode;
  text: string;
  selected?: boolean
}> = ({ href, icon, text, selected }) => {
  const selectedStyles = () => (selected ? selectedStyle : defaultStyle);

  return (
    <li className="leading-[48px] text-[15px] text-white-60">
      <Link
        href={href}
        className={`flex items-center px-[25px] ${selectedStyles()} hover:text-a-blue hover:stroke-a-blue hover:fill-a-blue rounded-[5px]`}>
        {icon}
        {text}
      </Link>
    </li>
  );
};
