import { useRouter } from 'next/router';
import { AssetCountIcon, AVFXSmall } from '../../modules/Icons';
import {
  AssetCounterProps,
  CollectionItemDetailsProps,
  ContributorDetailsProps,
  NonContributorDetailsProps
} from '../../types/components/shared/CollectionItemDetailsTypes';

export const AssetCounter: React.FC<AssetCounterProps> = ({ elements }) => (
  <div className="flex gap-1 text-right justify-self-end items-center text-[10px] lg:text-[14px] font-light">
    <AssetCountIcon />
    {elements}
  </div>
);

export const ContributorDetails: React.FC<ContributorDetailsProps> = ({ contributor, name }) => (
  <div className="grid w-full">
    <div className="grid grid-cols-[auto_1fr] items-center mb-[-5px]">
      <h2 className="truncate text-[12px] mobile-m:text-[14px] 4k:text-[16px] py-2 px-1 leading-snug inter">
        {name}
      </h2>
    </div>
    <p className="flex px-1 gap-1 items-center text-[10px] text-[#7F898D] font-500 mb-[-8px]">
      By {contributor.name}
    </p>
  </div>
);

export const NonContributorDetails: React.FC<NonContributorDetailsProps> = ({ name, hovered }) => {
  const router = useRouter();

  const shouldDisplayIcon = () => {
    const pathsToExclude = /\/c\/|\/accounts/;
    return !pathsToExclude.test(router.asPath);
  };

  return (
    <h2
      className="flex gap-1 whitespace-nowrap grow items-center text-[10px] mobile-m:text-[14px] 4k:text-[16px] py-2 px-1 leading-snug inter"
      style={{ minWidth: 0, maxWidth: 'calc(100% - 30px)' }}
      title={name}>
      {shouldDisplayIcon() && <AVFXSmall className="flex-shrink-0 !w-[18px] h-[10px] lg:w-[28px] lg:h-[16px]" fill={hovered ? 'white' : '#AEB6BA'} />}
      <span className="truncate">{name}</span>
    </h2>
  );
};

export const CollectionItemDetails: React.FC<CollectionItemDetailsProps> = ({
  contributor,
  name,
  hovered,
  elements
}) => (
  <div className="flex lg:justify-items-center items-center align-center gap-1 lg:gap-2 pt-1 h-[21px] mt-1">
    {contributor ? (
      <>
        <ContributorDetails contributor={contributor} name={name} />
        <AssetCounter elements={elements} />
      </>
    ) : (
      <>
        <NonContributorDetails name={name} hovered={hovered} />
        <AssetCounter elements={elements} />
      </>
    )}
  </div>
);
