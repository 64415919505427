import React from 'react';
import Link from 'next/link';
import { SubCategoryList } from './SubCategoryList';
import { CategoryFolderIcon } from '../../modules/Icons';
import { CategoryAssets } from '../../staticData/Categories';
import { categoryBaseUrl } from '../../helpers/sidebarHelpers';
import { CategoryItemProps } from '../../types/pages/products/CollectionsTypes';

export const selectedStyle = {
  text: 'text-a-blue',
  icon: 'text-[#00A1E0]'
};

export const unselectedStyle = {
  text: 'text-white-60',
  icon: 'text-[#767576]'
};

export const defaultStyle = 'flex items-center';
export const hoverStyle = 'group hover:text-a-blue [&:hover_svg]:text-[#00A1E0]';

export const CategoryItem: React.FC<CategoryItemProps> = ({
  category,
  selectedCategory,
  baseUrl,
  shallowLinks,
  onLinkClick,
  params
}) => {
  const isSelected = selectedCategory?.id === category.id || selectedCategory?.parent?.id === category.id;
  const styles = isSelected ? selectedStyle : unselectedStyle;

  const getCategoryIcon = (category) => {
    const staticCategory = CategoryAssets.find(
      (ca) => ca.slug === category.slug && ca.href.includes(`${categoryBaseUrl(params.library_id)}`)
    );
    if (staticCategory) {
      return <div className={styles.icon}>{staticCategory.icon}</div>;
    }
    return <CategoryFolderIcon className={`${styles.icon} w-4 mr-4 inline`} />;
  };

  return (
    <li className="leading-[48px] text-[15px]">
      <Link
        className={`${defaultStyle} ${hoverStyle}`}
        href={{ pathname: `${baseUrl}/${category.slug}/category` }}
        shallow={shallowLinks}>
        <span className={`flex w-full h-[38px] items-center group-hover:text-a-blue ${styles.text}`} onClick={onLinkClick}>
          {getCategoryIcon(category)}
          {category.name}
          {category.total_elements > 0 && (
            <span className="ml-auto text-[12px] text-[#4c5456] leading-48">
              {category.total_elements}
            </span>
          )}
        </span>
      </Link>
      {isSelected && (
        <SubCategoryList
          subcategories={category.subcategories}
          selectedCategory={selectedCategory}
          category={category}
          shallowLinks={shallowLinks}
          onLinkClick={onLinkClick}
        />
      )}
    </li>
  );
};
